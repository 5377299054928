import { defineStore } from "pinia"
import { UserDataTplClass,PubDataTplClass, UserDataTypeClass,DeviceClass } from "@/apis"
import { UserDataTplDto, SearchUserDataTplArgs, SearchPubDataTplArgs, pubDataTplDto, SearchUserDataTypeArgs, UserDataTypeDto, SearchDeviceArgs, DeviceDto, SearchDeviceStateArgs, DeviceStateDto, SearchHGRealtimeDataArgs, HGRealtimeDataDto } from "@/models/device"
import { ResponseDataType } from "@/models/types"

const userDataTpl = new UserDataTplClass()
const pubDataTpl = new PubDataTplClass()
const userDataType = new UserDataTypeClass()
const device = new DeviceClass()
export const useDataStore = defineStore('data',{
  state: () => {
    return {
      userDataTplTotal: 0,
      pubDataTplTotal: 0,
      userDataTypeTotal: 0,
      deviceTotal: 0,
      deviceStateTotal: 0,
      historyDataTotal: 0,
      
      pubDataTplList: <pubDataTplDto[]>[]
    }
  },
  getters: {},
  actions: {
    /**
     * 查询公共设备模板
     * @param params 
     * @returns 
     */
    getPubDataTpl(params: SearchPubDataTplArgs){
      return new Promise<pubDataTplDto[]>((resolve, reject) => {
        pubDataTpl.gets(params).then((resp: ResponseDataType<pubDataTplDto>) => {
          this.pubDataTplTotal = resp.total

          this.pubDataTplList = resp.data
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    /**
     * 查询用户设备模板
     * @param params SearchUserDataTplArgs
     * @returns UserDataTplDto[]
     */
    getUserDataTpl(params: SearchUserDataTplArgs){
      return new Promise<UserDataTplDto[]>((resolve, reject) => {
        userDataTpl.gets(params).then((resp: ResponseDataType<UserDataTplDto>) => {
          this.userDataTplTotal = resp.total
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 添加用户设备模板
     * @param data UserDataTplDto
     * @returns number
     */
    createUserDataTpl(data: UserDataTplDto){
      return new Promise<number>((resolve, reject) => {
        userDataTpl.create(data).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 编辑用户设备模板
     * @param data UserDataTplDto
     * @returns number
     */
    editUserDataTpl(data: UserDataTplDto){
      return new Promise<number>((resolve, reject) => {
        userDataTpl.update(data).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 删除用户设备模板
     * @param id number
     * @returns number
     */
    deleteUserDataTpl(id: number){
      return new Promise<number>((resolve, reject) => {
        userDataTpl.remove(id).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 用户设备模板详情
     * @param id number
     * @returns UserDataTplDto
     */
    detailUserDataTpl(id: number){
      return new Promise<UserDataTplDto>((resolve, reject) => {
        userDataTpl.detail(id).then((resp: UserDataTplDto) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    resetUserDataTpl(id: number){
      return new Promise<number>((resolve, reject) => {
        userDataTpl.resetUserDataTpl(id).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 获取用户设备模板数据类型
     * @param params 
     * @returns 
     */
    getUserDataType(params: SearchUserDataTypeArgs){
      return new Promise<UserDataTypeDto[]>((resolve, reject) => {
        userDataType.gets(params).then((resp: ResponseDataType<UserDataTypeDto>) => {
          this.userDataTypeTotal = resp.total
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 添加用户设备模板数据类型
     * @param data 
     * @returns 
     */
    createUserDataType(data: UserDataTypeDto){
      return new Promise<number>((resolve, reject) => {
        userDataType.create(data).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 编辑用户设备模板数据类型
     * @param data 
     * @returns 
     */
    editUserDataType(data: UserDataTypeDto){
      return new Promise<number>((resolve, reject) => {
        userDataType.update(data).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 删除用户设备模板数据类型
     * @param id 
     * @returns 
     */
    deleteUserDataType(id: number){
      return new Promise<number>((resolve, reject) => {
        userDataType.remove(id).then((resp: number) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 查询设备列表
     * @param params 
     * @returns 
     */
    getDevice(params: SearchDeviceArgs){
      return new Promise<DeviceDto[]>((resolve, reject) => {
        device.gets(params).then((resp: ResponseDataType<DeviceDto>) => {
          this.deviceTotal = resp.total
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 查询设备详情通过sn
     * @param sn 
     * @returns 
     */
    getDeviceDetail(sn: string){
      return new Promise<DeviceDto>((resolve, reject) => {
        device.detailBySN(sn).then((resp: DeviceDto) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 查询用户设备
     * @param params 
     * @returns 
     */
    getUserDevice(params: SearchDeviceArgs){
      return new Promise<DeviceDto[]>((resolve, reject) => {
        device.userGets(params).then((resp: ResponseDataType<DeviceDto>) => {
          this.deviceTotal = resp.total
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 查询设备上下线记录
     * @param params 
     * @returns 
     */
    getDeviceState(params: SearchDeviceStateArgs){
      return new Promise<DeviceStateDto[]>((resolve, reject) => {
        device.getStates(params).then((resp: ResponseDataType<DeviceStateDto>) => {
          this.deviceStateTotal = resp.total
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    /**
     * 查询设备历史数据
     * @param params SearchHGRealtimeDataArgs
     * @returns 
     */
    getHistory(params: SearchHGRealtimeDataArgs){
      return new Promise<HGRealtimeDataDto[]>((resolve, reject) => {
        device.getHistory(params).then((resp: ResponseDataType<HGRealtimeDataDto>) => {
          this.historyDataTotal = resp.total
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
})