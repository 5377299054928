import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "label-title"
}

import { ref, watch } from 'vue'

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'FormInput',
  props: {
  value: {
    type: String
  },

  title: {
    type: String,
    default: ''
  },

  placeholder: {
    type: String,
    default: ''
  },

  labelCol: {
    type: Number,
    default: 4
  },

  valueCol: {
    type: Number,
    default: 16
  },

  disabled: {
    type: Boolean,
    default: false
  },

  type: {
    type: String,
    default: 'text'
  },
  showTitle: {
    type: Boolean,
    default: true
  }
},
  emits: ['ok', 'update:value'],
  setup(__props, { emit: __emit }) {

const props = __props

// eslint-disable-next-line no-undef
const emits = __emit

const inputValue = ref(props.value)
watch(props, e => {
  inputValue.value = e.value
})

const onChange = e => {
  emits('ok', e.target.value)
  emits('update:value', e.target.value)
}

return (_ctx: any,_cache: any) => {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "search-params-margin" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        span: __props.showTitle ? __props.labelCol : 0
      }, {
        default: _withCtx(() => [
          (__props.showTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.title), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["span"]),
      _createVNode(_component_a_col, {
        span: __props.showTitle ? __props.valueCol : 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            value: inputValue.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
            onChange: onChange,
            allowClear: "",
            disabled: __props.disabled,
            type: __props.type,
            placeholder: __props.placeholder ? __props.placeholder : ('请输入' + __props.title)
          }, null, 8, ["value", "disabled", "type", "placeholder"])
        ]),
        _: 1
      }, 8, ["span"])
    ]),
    _: 1
  }))
}
}

})