import { OptionsType } from "@/models/types"
import dayjs from "dayjs"
import 'dayjs/locale/zh-cn'

export default {
  formatDate(time: string, mode='YYYY-MM-DD HH:mm:ss'){
    return dayjs(time).locale('zh-cn').format(mode)
  },

  getOptionlabel(options: OptionsType[], value: number|string){
    return options.find(x => x.value === value)?.label
  },

  dayjsFormat(date: dayjs.Dayjs, mode='YYYY-MM-DD HH:mm:ss'){
    return date.locale('zh-cn').format(mode)
  }
}