<template>
  <a-row :style="{
    'margin-bottom': margin + 'px'
  }">
    <a-col :span="labelCol" v-if="showTitle">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-tree-select
        v-model:value="inputValue"
        style="width: 100%"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="options"
        :fieldNames="fieldNames"
        :placeholder="'选择' + title"
        tree-default-expand-all
        :disabled="disabled"
        @change="onChange"
      >
      </a-tree-select>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    margin: {
      type: Number,
      default: 10
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    options: {
      type: Array,
      default: () => []
    },

    fieldNames: {
      type: Object,
      default: () => null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>
