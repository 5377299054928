import { UpdateUserArgs, User, UserLoginDto, UserRegistDto } from "@/models/user"
import { BaseClass } from "./base"
import { Menus } from "@/models/role"
import { UserDataTplDto, SearchUserDataTplArgs, SearchPubDataTplArgs, pubDataTplDto, UserDataTypeDto, SearchUserDataTypeArgs, SearchDeviceArgs, DeviceDto, SearchDeviceStateArgs, DeviceStateDto, SearchHGRealtimeDataArgs, HGRealtimeDataDto } from "@/models/device"
import { IndustryDto, ResponseDataType } from "@/models/types"

/**
 * 用户类
 */
export class UserClass extends BaseClass{
  /**
   * 登录
   * @param username 用户名
   * @param password 密码
   * @returns 
   */
  login(username: string, password: string){
    return this.post<UserLoginDto>(this.ucenter + '/Account/ManageLogin', {username, password})
  }

  /**
   * 获取用户缓存
   * @returns User
   */
  info(){
    return this.get<User>(this.ucenter + '/Account/UserCache')
  }

  /**
   * 更新用户信息
   * @param data UpdateUserArgs
   * @returns 
   */
  updateInfo(data: UpdateUserArgs){
    return this.put<number>(this.ucenter + '/User/Self', data)
  }

  /**
   * 获取验证码
   * @param mobile 手机号
   * @returns 
   */
  getSms(mobile: string){
    return this.get<number>(this.ucenter + `/SMS/SendCode/${mobile}`)
  }

  /**
   * 验证手机验证码
   * @param mobile 手机号
   * @param code 验证码
   * @returns 
   */
  checkSms(mobile: string, code: string){
    return this.get<number>(this.ucenter + `/SMS/VerifyCode/${mobile}/${code}`)
  }

  /**
   * 用户注册
   * @param data UserRegistDto
   * @returns 
   */
  regist(data: UserRegistDto){
    return this.post<number>(this.ucenter + '/Account/UserRegist', data) 
  }

  menus(){
    return this.get<Menus[]>(this.ucenter + '/Menu/GetUserRoleMenus')
  }

  checkUserInIot(){
    return this.get<number>(this.iot + '/User/CheckUserInIot')
  }
}

/**
 * 用户设备模板类
 */
export class UserDataTplClass extends BaseClass{
  modelRef = <UserDataTplDto>{
    name: '',
    industryId: undefined,
    deviceTypeId: undefined
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入模板名称'
      }
    ],
    industryId: [
      {
        required: true,
        message: '请选择所属行业'
      }
    ],
    deviceTypeId: [
      {
        required: true,
        message: '请选择设备类型'
      }
    ]
  }

  /**
   * 查询模板列表
   * @param params 
   * @returns 
   */
  gets(params: SearchUserDataTplArgs){
    return this.get<ResponseDataType<UserDataTplDto>>(this.iot + '/UserDataTpl', params)
  }

  /**
   * 查询模板详情
   * @param id 
   * @returns 
   */
  detail(id: number){
    return this.get<UserDataTplDto>(this.iot + `/UserDataTpl/${id}`)
  }

  /**
   * 添加模板
   * @param data 
   * @returns 
   */
  create(data: UserDataTplDto){
    return this.post<number>(this.iot + '/UserDataTpl', data)
  }

  /**
   * 编辑模板
   * @param data 
   * @returns 
   */
  update(data: UserDataTplDto){
    return this.put<number>(this.iot + '/UserDataTpl', data)
  }

  /**
   * 删除模板
   * @param id 
   * @returns 
   */
  remove(id: number){
    return this.delete<number>(this.iot + `/UserDataTpl/${id}`)
  }

  /**
   * 重置数据模板缓存
   * @param id 
   * @returns 
   */
  resetUserDataTpl(id: number){
    return this.get<number>(this.iot + `/UserDataTpl/ResetUserDataTpl/${id}`)
  }
}

export class PubDataTplClass extends BaseClass{
  gets(params: SearchPubDataTplArgs){
    return this.get<ResponseDataType<pubDataTplDto>>(this.iot + '/PubDataTpl', params)
  }
}

/**
 * 设备类
 */
export class DeviceClass extends BaseClass{
  /**
   * 查询行业 + 设备类型tree结构数据
   * @returns 
   */
  getIndustryOptions(){
    return this.get<IndustryDto[]>(this.iot + '/Device/GetIndustryOptions')
  }

  /**
   * 查询设备
   * @param params SearchDeviceArgs
   * @returns DeviceDto
   */
  gets(params: SearchDeviceArgs){
    return this.get<ResponseDataType<DeviceDto>>(this.iot + '/Device', params)
  }

  /**
   * 设备详情
   * @param id 
   * @returns 
   */
  detail(id: string){
    return this.get<DeviceDto>(this.iot + `/Device/${id}`)
  }

  /**
   * 根据sn获取设备详情
   * @param sn 
   * @returns 
   */
  detailBySN(sn: string){
    return this.get<DeviceDto>(this.iot + `/Device/DetailBySN/${sn}`)
  }

  /**
   * 历史数据查询
   * @param params 
   * @returns 
   */
  getHistory(params: SearchHGRealtimeDataArgs){
    return this.get<ResponseDataType<HGRealtimeDataDto>>(this.iot + '/Device/GetHistoryDatas', params)
  }

  /**
   * 用户查询绑定的设备
   * @param params 
   * @returns 
   */
  userGets(params: SearchDeviceArgs){
    return this.get<ResponseDataType<DeviceDto>>(this.iot + '/Device/UserGets', params)
  }

  /**
   * 查询设备上下线日志
   * @param params 
   * @returns 
   */
  getStates(params: SearchDeviceStateArgs){
    return this.get<ResponseDataType<DeviceStateDto>>(this.iot + '/DeviceState', params)
  }

  /**
   * 单个IO控制
   * @param SN 序列号
   * @param IO IO地址
   * @param Value 值 0：关，1：开
   * @returns 
   */
  IOControl(SN: string,IO: number,Value: number){
    return this.get(this.iot + '/Device/IOControl', {SN,IO,Value})
  }

  /**
   * 数据下发
   * @param SN 序列号
   * @param Type 数据类型
   * @param Len 数据长度
   * @param Data 数据值
   * @returns 
   */
  dataDown(SN: string, Type: number, Len: number, Data: string){
    return this.get(this.iot + '/Device/DataDown', {SN,Type,Len,Data})
  }
}

export class UserDataTypeClass extends BaseClass{
  modelRef = <UserDataTypeDto>{
    name: '',
    no: 0,
    type: 0,
    dataLen: 2,
    tag: '',
    remark: ''
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入数据类型名称'
      }
    ],
    tag: [
      {
        required: true,
        message: '请输入数据类型标识'
      }
    ],
    no: [
      {
        required: true,
        message: '请输入数据类型编号'
      }
    ]
  }

  /**
   * 查询数据类型列表
   * @param params SearchUserDataTypeArgs
   * @returns UserDataTypeDto
   */
  gets(params: SearchUserDataTypeArgs){
    return this.get<ResponseDataType<UserDataTypeDto>>(this.iot + '/UserDataType', params)
  }

  /**
   * 添加数据类型
   * @param data UserDataTypeDto
   * @returns number
   */
  create(data: UserDataTypeDto){
    return this.post<number>(this.iot + '/UserDataType', data)
  }

  /**
   * 编辑数据类型
   * @param data UserDataTypeDto
   * @returns number
   */
  update(data: UserDataTypeDto){
    return this.put<number>(this.iot + '/UserDataType', data)
  }

  /**
   * 删除数据类型
   * @param id 
   * @returns number
   */
  remove(id: number){
    return this.delete<number>(this.iot + `/UserDataType/${id}`)
  }
}