import router from './router'
import { setDocumentTitle } from './utils'
import storage from './utils/storage'
import defaultConfig from './configs/default.config'
import { useUserStore, useAppStore } from './store'
import { Menus } from './models/role'
import { RouteRecordRaw } from 'vue-router'

const whiteList = ['iot-login', 'iot-regist', 'iot-forget', 'iot-forgetpsw']
export default {
  init() {
    console.log('载入路由')
    const userStore = useUserStore()
    const appStore = useAppStore()
    router.beforeEach((to, from, next) => {
      to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${defaultConfig.project_title}`))
      if (storage.get('BEARER_TOKEN')) {
        if (userStore.menus.length === 0) {
          userStore.onGetUserInfo().then(() => {
            userStore.onGetMenus().then((resp) => {
              appStore.onGetIndustrys().then(() => {
                userStore.GenerateRoutes(resp as Menus[]).then((routes: any) => {
                  routes.forEach((element: RouteRecordRaw) => {
                    router.addRoute(element)
                  })
                  next({ ...to, replace: true })
                })
              })
            })
          })
        } else {
          if (to.name === 'iot-login') {
            next({ name: 'iot-control' })
          } else {
            next()
          }
        }
      } else {
        userStore.menus = []
        // 返回登陆
        if (whiteList.includes(to.name as string)) {
          next()
        } else {
          next({ name: 'iot-login' })
        }
      }
    })

    router.afterEach(() => {
      console.log('done')
    })
  }
}