import defaultConfig from "@/configs/default.config"

/**
 * 设置浏览器标题
 * @param title 
 */
export function setDocumentTitle(title: string){
  document.title = title
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}

/**
 * 数据转成tree结构
 * @param data 
 * @param parentId 
 * @returns 
 */
export function dataToPromiseTree (data: any[], parentId: number) {
  const len = data.length
  const tree = []
  for (let i = 0; i < len; i++) {
    if (data[i].parentId === parentId) {
      const obj = {
        ...data[i]
      }
      obj.actionList = dataToPromiseTree(data, data[i].id)
      tree.push(obj)
    }
  }
  return tree
}

/**
 * 判断是否有对应的权限
 * @param permission 
 * @param route 
 * @returns 
 */
export function hasPermission (permission: any, route: any) {
  if (defaultConfig.check_auth === 'false') {
    return true
  }

  if (route.meta && route.meta.permission) {
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.permission.includes(permission[i].sign)
      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

/**
 * 过滤权限菜单
 * @param routerMap 
 * @param roles 
 * @returns 
 */
export function filterAsyncRouter (routerMap:any, roles:any) {
  const accessedRouters = routerMap.filter((route: any) => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

/**
 * 绑定事件
 * @param obj 事件对象
 * @param sType 事件名称
 * @param fn 回调函数
 */
export function addEvent (obj: any, sType: string, fn: any) {
  if (obj.addEventListener) {
    obj.addEventListener(sType, fn, false)
  } else {
    obj.attachEvent('on' + sType, fn)
  }
}

/**
 * 移除事件
 * @param obj 事件对象
 * @param sType 事件名称
 * @param fn 回调函数
 */
export function removeEvent (obj: any, sType: string, fn: any) {
  if (obj.removeEventListener) {
    obj.removeEventListener(sType, fn, false)
  } else {
    obj.detachEvent('on' + sType, fn)
  }
}

/**
 * 当前事件
 * @param ev 事件
 * @returns 
 */
export function prEvent (ev: any) {
  const oEvent = ev || window.event
  if (oEvent.preventDefault) {
    oEvent.preventDefault()
  }
  return oEvent
}

/**
 * 添加滚轮事件
 * @param obj 绑定事件对象
 * @param callback 回调函数
 */
export function addWheelEvent (obj:any, fn:any) {
  if (window.navigator.userAgent.toLowerCase().indexOf('firefox') !== -1) {
    addEvent(obj, 'DOMMouseScroll', wheel)
  } else {
    addEvent(obj, 'mousewheel', wheel)
  }
  function wheel (ev: any) {
    const oEvent = prEvent(ev)
    const delta = oEvent.detail ? oEvent.detail > 0 : oEvent.wheelDelta < 0
    fn && fn.call(oEvent, delta)
    return false
  }
}