import {PublicLayout, BasicLayout, RouterLayout, DeviceLayout} from '@/layout'
import { RouteRecordRaw } from 'vue-router'

// 权限菜单
const roleRoutes:Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: '/iot/control',
    component: BasicLayout,
    children: [
      {
        path: '/iot/control',
        name: 'iot-control',
        component: () => import('@/views/control/ControlIndex.vue'),
        meta: { title: '控制台', permission: ['iot-control'] }
      },
      {
        path: '/iot/device',
        name: 'iot-device',
        redirect: '/iot/tpl/index',
        component: DeviceLayout,
        meta: { title: '接入设备' },
        children: [
          {
            path: '/iot/tpl/index',
            name: 'iot-tpl-index',
            redirect: '/iot/tpl/list',
            component: RouterLayout,
            meta: { title: '设备模板' },
            children: [
              {
                path: '/iot/tpl/list',
                name: 'iot-tpl-list',
                component: () => import('@/views/control/device/DeviceTpl.vue'),
                meta: { title: '设备模板列表', permission: ['iot-tpl-list'] }
              },
              {
                path: '/iot/tpl/models',
                name: 'iot-tpl-models',
                component: () => import('@/views/control/device/DeviceTplModel.vue'),
                meta: { title: '编辑设备模板物模型', permission: ['iot-tpl-models'] }
              }
            ]
          },
          {
            path: '/iot/device/index',
            name: 'iot-device-index',
            redirect: '/iot/device/list',
            component: RouterLayout,
            meta: { title: '设备管理' },
            children: [
              {
                path: '/iot/device/list',
                name: 'iot-device-list',
                component: () => import('@/views/control/device/DeviceList.vue'),
                meta: { title: '设备管理', permission: ['iot-device-list'] }
              },
              {
                path: '/iot/device/history-datas',
                name: 'iot-device-history-datas',
                component: () => import('@/views/control/device/HistoryData.vue'),
                meta: { title: '历史数据', permission: ['iot-device-history-datas'] }
              }
            ]
          },
          {
            path: '/iot/question/list',
            name: 'iot-question-list',
            component: () => import('@/views/control/device/DeviceQuestion.vue'),
            meta: { title: '问题反馈', permission: ['iot-question-list'] }
          }
        ]
      }
    ]
  }
]

// 通用菜单
const normalRoutes:Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: '/iot/control',
    component: BasicLayout,
    children: []
  },
  {
    path: '/iot',
    component: PublicLayout,
    redirect: '/iot/login',
    children: [
      {
        path: '/iot/login',
        name: 'iot-login',
        component: () => import('@/views/public/IotLogin.vue'),
        meta: { title: '登录' }
      },
      {
        path: '/iot/regist',
        name: 'iot-regist',
        component: () => import('@/views/public/IotRegist.vue'),
        meta: { title: '注册' }
      },
      {
        path: '/iot/forget',
        name: 'iot-forget',
        component: () => import('@/views/public/IotForget.vue'),
        meta: { title: '忘记账号' }
      },
      {
        path: '/iot/forgetpsw',
        name: 'iot-forgetpsw',
        component: () => import('@/views/public/IotForgetPsw.vue'),
        meta: { title: '忘记密码' }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/public/exception/NotFound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/public/exception/NotFound.vue')
  }
]


export {roleRoutes, normalRoutes}