import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { class: "public-layout" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  href: "/",
  class: "logo"
}
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "footer" }
const _hoisted_6 = { class: "copyright" }

import defaultConfig from '@/configs/default.config'


export default /*@__PURE__*/_defineComponent({
  __name: 'PublicLayout',
  setup(__props) {

const {project_title, company} = defaultConfig

return (_ctx: any,_cache: any) => {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _createTextVNode(" " + _toDisplayString(_unref(project_title)), 1)
      ]),
      _createVNode(_component_a_divider, { type: "vertical" }),
      _createElementVNode("h1", null, _toDisplayString(_ctx.$route.meta.title), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, "Copyright © 2024 - 2025 HGIOT. All Rights Reserved. " + _toDisplayString(_unref(company)) + " 版权所有", 1)
    ])
  ]))
}
}

})