import request from '@/utils/request'

export class BaseClass {
  ucenter = '/ucenter/api'
  rc = '/rc/api'
  iot = '/iotapi/api'

  get<T>(url: string, params?: any): Promise<T>{
    return request({
      url, method: 'GET', params
    })
  }

  post<T>(url: string, data:any, responseType = 'json', options = {}): Promise<T>{
    return request({
      url, method: 'POST', data, headers: { responseType }, ...options
    })
  }

  put<T>(url: string, data:any): Promise<T>{
    return request({
      url, method: 'PUT', data
    })
  }

  delete<T> (url: string,  params?: any): Promise<T> {
    return request({
      url, method: 'DELETE', params
    })
  }

  upload<T> (url: string, data: any): Promise<T> {
    return request({
      url, method: 'POST', data, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  export<T> (url: string, method: string, params: any): Promise<T> {
    const options = {
      url, method, headers: { responseType: 'blob' }, timeout: 0, data: null, params: null
    }
    if (method === 'POST') {
      options.data = params
    } else {
      options.params = params
    }
    return request(options)
  }
}