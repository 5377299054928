<template>
  <div class="device-layout">
    <div class="left-menus">
      <div class="menu-list">
        <router-link to="/iot/tpl/index" class="menu-item">
          <GroupOutlined />
          <span class="menu-title">设备模板</span>
        </router-link>

        <router-link to="/iot/device/index" class="menu-item">
          <BarsOutlined />
          <span class="menu-title">设备列表</span>
        </router-link>

        <router-link to="/iot/question/list" class="menu-item">
          <ExclamationCircleOutlined />
          <span class="menu-title">问题反馈</span>
        </router-link>
      </div>
    </div>
    <div class="work-area">
      <div class="body-view">
        <div class="device-content" ref="deviceContentRef">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {GroupOutlined, BarsOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue'
import { nextTick, onMounted, ref } from 'vue'
import { useAppStore } from '@/store'

const appStore = useAppStore()

const deviceContentRef = ref()


onMounted(() => {
  nextTick(() => {
    appStore.setDeviceContentHeight(deviceContentRef.value.offsetHeight)
  })
})
</script>

<style lang="less" scoped>
.device-layout{
  width: 100%;
  height: 100%;
  display: flex;
  .left-menus{
    width: 200px;
    background-color: #202020;

    .menu-list{
      padding-left: 10px;
      .menu-item{
        display: flex;
        align-items: center;
        padding: 15px 20px;
        color: #fff;
        transition: all .3s ease;
        font-size: 14px;
        line-height: 20px;
        border-radius: 10px 0 0 10px;

        &.router-link-active,&:hover{
          background-color: #1677ff;
        }
        .menu-title{
          padding-left: 10px;
        }
      }
    }
  }
  .work-area{
    flex: 1;
    overflow: hidden;

    .body-view {
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      
      padding: 20px;
      .device-content{
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 4px;
        overflow: auto;
        position: relative;
      }
    }
  }
}
</style>