import { createFromIconfontCN } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '/icon-font.js'
})

export default defineComponent({
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return () => <IconFont type={props.type} />
  }
})