import { defineStore } from "pinia"
import { DeviceClass } from "@/apis"
import { IndustryDto } from "@/models/types"

const deviceApi = new DeviceClass()
export const useAppStore = defineStore('app', {
  state: () => {
    return {
      host: '' as string,
      company: '' as string,
      version: '' as string,
      projectTitle: '' as string,
      rcToken: '' as string,
      checkAuth: false as boolean,

      deviceContentHeight: 0,
      industrys: <IndustryDto[]>[],
      dataTypes: [
        {label: '数值', value: 0},
        {label: '字符串', value: 1},
        {label: 'BCD编码', value: 2},
        {label: '开关', value: 3},
        {label: '经纬度', value: 4},
      ],
      onLineOptions: [
        {label: '在线', value: true},
        {label: '离线', value: false}
      ]
    }
  },
  getters: {},
  actions: {
    setDeviceContentHeight(value: number){
      this.deviceContentHeight = value
      console.log('设备工作区高度：',value + 'px')
    },

    onGetIndustrys(){
      return new Promise((resolve, reject) => {
        deviceApi.getIndustryOptions().then((resp) => {

          this.industrys = resp
          
          resolve(1)
        }).catch(e => {
          reject(e)
        })
      })
    },

    IOControl(SN: string, IO: number, Value: number){
      return new Promise((resolve, reject) => {
        deviceApi.IOControl(SN, IO, Value).then(() => {          
          resolve(1)
        }).catch(e => {
          reject(e)
        })
      })
    },
    
    dataDown(SN: string, Type: number, Len: number, Data: string){
      return new Promise((resolve, reject) => {
        deviceApi.dataDown(SN,Type,Len,Data).then(() => {          
          resolve(1)
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
})