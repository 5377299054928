export default {
  get(key: string){
    const str = localStorage.getItem(key)
    if(str){
      return JSON.parse(str)
    }

    return null;
  },

  set(key:string, value: any){
    localStorage.setItem(key, JSON.stringify(value))
  },

  remove(key: string){
    localStorage.removeItem(key)
  },

  clearAll(){
    localStorage.clear()
  }
}