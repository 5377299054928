import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_3 = { class: "btns" }

import { addEvent, prEvent, removeEvent } from '@/utils';
import {CloseOutlined} from '@ant-design/icons-vue'
import { nextTick, onMounted, ref, watch } from 'vue';
// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'EpModal',
  props: {
    visible: Boolean,
    onlyBody: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: String,
    okText: {
      type: String,
      default: '确定'
    },
    offsetTop: Number,
    closeAble: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 0
    }
  },
  emits: ['close', 'ok'],
  setup(__props, { emit: __emit }) {

const props = __props

  const top = ref(0)
    const left = ref(0)
    const show = ref(false)

    // eslint-disable-next-line no-undef
    const emit = __emit
    const onClose = () => {
      emit('close')
    }

    const onOk = () => {
      emit('ok')
    }

    const dragBoxRef = ref()
    const dragAbleRef = ref()
    const setModalStyle = () => {
      const w = props.width
      const tW = document.body.clientWidth
      const tH = document.body.clientHeight
      const h = dragBoxRef.value.clientHeight
      left.value = (tW - w) / 2
      const jstop = props.offsetTop ? props.offsetTop : (tH - h) / 2
      show.value = true
      top.value = jstop > 0 ? jstop : 0
      console.log(top.value)
    }

    const onDrag = () => {
      const drag = dragAbleRef.value
      const body = dragBoxRef.value

      addEvent(drag, 'mousedown', function (ev: any) {
        var oEvent = prEvent(ev)
        var oParent = body.parentNode
        var disX = oEvent?.clientX - body.offsetLeft
        var disY = oEvent?.clientY - body.offsetTop
        var startMove = function (ev:any) {
          if (oParent.setCapture) {
            oParent.setCapture()
          }
          var oEvent = ev || window.event
          var L = oEvent.clientX - disX
          var T = oEvent.clientY - disY
          body.style.left = L + 'px'
          body.style.top = T + 'px'

          oParent.onselectstart = function () {
            return false
          }
        }
        var endMove = function () {
          if (oParent.releaseCapture) {
            oParent.releaseCapture()
          }
          oParent.onselectstart = null

          removeEvent(oParent, 'mousemove', startMove)
          removeEvent(oParent, 'mouseup', endMove)
        }
        addEvent(oParent, 'mousemove', startMove)
        addEvent(oParent, 'mouseup', endMove)
        return false
      })
    }

    onMounted(() => {
      nextTick(() => {
        onDrag()
      })
    })

    watch(() => props.visible, (val) => {
      if (val) {
        setTimeout(() => {
          setModalStyle()
        }, 10)
      }
    })

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      class: "cover",
      ref: "coverRef",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseCover && _ctx.onCloseCover(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({
          'modal-box': true,
          show: show.value,
        }),
        style: _normalizeStyle({
          width: __props.width + 'px',
          top: top.value + 'px',
          left: left.value + 'px',
        }),
        ref_key: "dragBoxRef",
        ref: dragBoxRef,
        onClick: _withModifiers(() => false, ["stop"])
      }, [
        _createElementVNode("div", {
          class: "modal-title",
          ref_key: "dragAbleRef",
          ref: dragAbleRef
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(__props.title), 1),
          _createElementVNode("a", { onClick: onClose }, [
            _createVNode(_unref(CloseOutlined), { style: {"font-size":"16px"} })
          ])
        ], 512),
        _createElementVNode("div", {
          class: "modal-body",
          style: _normalizeStyle({
            height: __props.height > 0 ? __props.height + 'px' : 'auto',
          })
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 4),
        (__props.footer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_button, {
                    style: {"margin-right":"15px"},
                    onClick: onClose
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("取消")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: onOk,
                    loading: __props.loading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.okText), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 6)
    ], 512), [
      [_vShow, __props.visible]
    ])
  ]))
}
}

})